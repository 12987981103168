.price-card {
   border: .25rem solid rgb( var(--primary) );
   border-radius: .8rem;
   padding: 2rem;
   text-align: left;
}

.price-card h1 {
   font-size: 3rem;
   color: rgb( var(--primary) );
}

.price-card > ul {
   margin-bottom: 1.5rem;
}
.price-card > ul li {
   padding-bottom: .5rem;
}