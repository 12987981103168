.team-vertical {
   display: grid;
   grid-template-columns: 1fr min-content 1.2rem;
   align-items: start;
}

.team-vertical img {
   border-radius: .8rem;
}

.team-vertical h3, .team-vertical h2 {
   writing-mode: vertical-rl;
}

.sponsor > img {
   padding-inline: 2rem;
}

.photos {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   padding: 0;
}
.photos img.span2 {
   grid-column: span 2;
}

@media (max-width: 72em) {
   section.sponsor {
      grid-template-columns: repeat(2, 1fr);
   }
   .sponsor > img {
      padding-inline: .5rem;
   }
   .photos {
      grid-template-columns: 1fr;
   }
   .photos img.span2 {
      grid-column: unset;
   }
}